<template>
  <transition-group
    ref="smoothList"
    name="fade-list"
    class="fade-list-wrapper"
    :tag="tag"
    @before-leave="beforeLeave"
    @after-leave="clearHeightAfter"
    @leave="leave"
    @before-enter="beforeEnter"
    @after-enter="clearHeightAfter"
    @enter="enter"
  >
    <slot />
  </transition-group>
</template>

<script>
import { isServer } from '@vue-storefront/core/helpers';

export default {
  name: 'ASmoothList',
  props: {
    tag: {
      type: String,
      default: 'div'
    }
  },
  data () {
    return {
      height: null
    }
  },
  methods: {
    beforeEnter () {
      const height = this.$refs.smoothList.$el.clientHeight

      this.$refs.smoothList.$el.style = `min-height: ${height}px`
    },
    enter () {
      if (isServer) return
      setTimeout(() => this.calculateHeight(), 0)
    },
    beforeLeave () {
      const height = this.$refs.smoothList.$el.clientHeight
      this.$refs.smoothList.$el.style = `min-height: ${height}px`
    },
    leave () {
      if (isServer) return

      setTimeout(() => this.calculateHeight(true), 1000)
    },
    clearHeightAfter () {
      if (isServer) return

      return setTimeout(() => {
        this.$refs.smoothList.$el.style = ''
      }, 1000)
    },
    calculateHeight (leave = false) {
      if (isServer) return

      const calculate = {
        children: [...this.$refs.smoothList.$el.children]
      }

      if (leave) {
        calculate.children = calculate.children
          .filter(el => !el.classList.contains('fade-list-leave-active'))
      }

      const childrenHeight = calculate.children.map(el => el.scrollHeight)
        .reduce((a, b) => a + b, 0)

      setTimeout(() => {
        this.$refs.smoothList.$el.style = `min-height: ${childrenHeight}px`
      }, 0)

      return childrenHeight
    }
  }
}
</script>

<style lang="scss">
.fade-list-wrapper {
  transition: all .5s ease;
  position: relative;

  > * {
    transition: all .5s ease;

    &:first-child.fade-list-leave-active {
      top: -20px
    }

    &:first-child.fade-list-enter-active {
      opacity: 0;
      top: -20px
    }

    &:first-child.fade-list-leave-to {
      top: -20px!important;
    }

    &:first-child.fade-list-enter-to {
      opacity: 1;
      top: 0
    }
  }
}

.fade-list-move, /* apply transition to moving elements */
.fade-list-enter-active,
.fade-list-leave-active {
  transition: all .5s ease;
}

.fade-list-enter-from,
.fade-list-leave-to {
  opacity: 0!important;
}

.fade-list-enter-active,
.fade-list-leave-active {
  position: absolute!important;
  left: 0;
  right: 0;
}
</style>
