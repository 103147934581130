<template>
  <div class="aft">
    <div class="aft__tooltip">
      <slot name="tooltip" />
    </div>

    <slot/>
  </div>
</template>

<style scoped lang="scss">
.aft {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: var(--font-size-14);

  &__tooltip {
    visibility: hidden;
    background-color: black;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 5px 10px;
    white-space: nowrap;
    position: absolute;
    z-index: 1;
    bottom: calc(100% + 5px);

    /* Fade in tooltip - takes 1 second to go from 0% to 100% opac: */
    opacity: 0;
    transition: opacity 1s;

    &:before {
      content: " ";
      position: absolute;
      right: 50%;
      top: 100%;
      margin-right: -5px;
      border-width: 5px;
      border-style: solid;
      border-color: black transparent transparent transparent;
    }
  }

  &:hover .aft__tooltip {
    visibility: visible;
    opacity: 1;
  }
}
</style>

<script>
export default {
  name: 'AFloatingTooltip'
};
</script>
